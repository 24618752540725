





















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ResPostSubscription } from '@/gen';
import { calcNextPayDt } from '@/common/subscriptionUtils';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';

@Component
export default class RegisteredComplete extends Vue {
  // サブスク登録APIレスポンス
  @Prop()
  resPostSubscription!: ResPostSubscription;
  // サブスクパッケージ情報
  @Prop()
  subscriptionPackageInfo!: SubscriptionPackageItem;
  // 次回更新料金無料判定フラグ
  @Prop()
  isNextUpdateForFree!: boolean;

  get nextPayDt() {
    return calcNextPayDt(
      this.resPostSubscription.pay_interval,
      this.resPostSubscription.start_dt
    );
  }
}
